export default {
  data() {
    return {
      formData: {
        Phone: "",
        Password: "",
        ConfirmPassword: "",
        ImgCode: "",
        PhoneCode: "",
        BackstageName: "",
        SellerName: "",
        Contacts: "",
        FixedPhone: "",
        Region: "",
        SellerAddress: "",
        SellerFile: "",
        OfficialWebsite:"",
        SellerIntroduction: ""
      },
      rules: {
        Phone: [{required: true, message: "请输入正确的手机号码"}],
        Password: [{required: true, message: "请输入密码"}],
        ConfirmPassword: [{required: true, message: "请再次输入密码"}],
        ImgCode: [{required: true, message: "请输入正确的验证码"}],
        PhoneCode: [{required: true, message: "请输入正确的手机验证码"}],
        BackstageName: [{required: true, message: "请输入后台用户名"}],
        SellerName: [{required: true, message: "请输入商家全称"}],
        Contacts: [{required: true, message: "请输入联系人"}],
        FixedPhone: [{required: true, message: "请输入固定电话"}],
        Region: [{required: true, message: "请选择所在地区"}],
        SellerAddress: [{required: true, message: "请输入详细地址"}],
        SellerFile: [{required: true, message: "请上传商家资质材料"}],
      }
    }
  }
}