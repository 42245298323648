<template>
  <div class="register">
    <div class="main">
      <div class="head flex-start">
        <div class="logo center">
          <img @click="toHome" src="@/assets/logo.png">
          <span @click="toHome">墨库商城</span>
        </div>
        <div class="text">欢迎注册</div>
        <div class="login">已经注册，直接<span @click="toLogin">登录</span></div>
      </div>
      <div class="box">
        <el-form ref="form" :rules="rules" :model="formData" label-position="right" label-width="200px" style="width: ">
          <el-form-item label="手机号码" prop="Phone">
            <el-input v-model="formData.Phone" style="width: 600px" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="设置密码" prop="Password">
            <el-input v-model="formData.Password" style="width: 600px" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="ConfirmPassword">
            <el-input v-model="formData.ConfirmPassword" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="图像验证码" prop="ImgCode">
            <el-input v-model="formData.ImgCode" style="width: 300px" placeholder="请输入图像验证码"></el-input>
            <img src="@/assets/test.jpg" class="imgcode">
          </el-form-item>
          <el-form-item label="手机验证码" prop="PhoneCode">
            <el-input v-model="formData.PhoneCode" style="width: 300px" placeholder="请输入手机验证码"></el-input>
            <el-button type="primary" style="marginLeft: 20px">发送验证码</el-button>
          </el-form-item>
          <el-form-item label="后台用户名" prop="BackstageName">
            <el-input v-model="formData.BackstageName" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="商家全称" prop="SellerName">
            <el-input v-model="formData.SellerName" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="Contacts">
            <el-input v-model="formData.Contacts" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="固定电话" prop="FixedPhone">
            <el-input v-model="formData.FixedPhone" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="地区选择" prop="Region">
            <el-select v-model="formData.Region" placeholder="请选择所在地区" style="width: 300px">
              <el-option
                v-for="item in area"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="SellerAddress">
            <el-input v-model="formData.SellerAddress" style="width: 300px" placeholder="请再次输入密码"></el-input>
            <el-button type="primary" style="marginLeft: 20px">选择资料</el-button>
          </el-form-item>
          <el-form-item label="资质材料" prop="SellerFile">
            <el-input v-model="formData.SellerFile" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="企业官网" prop="OfficialWebsite">
            <el-input v-model="formData.OfficialWebsite" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item label="商家简介" prop="SellerIntroduction">
            <el-input v-model="formData.SellerIntroduction" style="width: 600px" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">确认注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import register from './register'
export default {
  name: "register",
  mixins: [register],
  data() {
    return {
      area: [
        {label: "广州", value: "广州"}
      ]
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate(() => {

      })
    },
    toLogin() {
      this.$router.push({
        name: "Login"
      })
    },
    toHome() {
      this.$router.push({
        name: "Home"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
    .head {
      width: 100%;
      position: relative;
      .logo {
        height: 121px;
        font-size: 30px;
        font-weight: 400;
        color: #202020;
        img {
          width: 42px;
          height: 42px;
          margin-right: 12px;
        }
      }
      .text {
        height: 121px;
        font-size: 30px;
        color: #0AC8F9;
        line-height: 121px;
        margin-left: 30px;
        padding-left: 30px;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 2px;
          height: 60px;
          background: #cccccc;
          position: absolute;
          top: 50%;
          margin-top: -30px;
          left: 0;
        }
      }
      .login {
        height: 121px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 20px;
        padding-right: 20px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        color: #202020;
        span {
          color: #0AC8F9;
          cursor: pointer;
        }
      }
    }
    .box {
      width: 100%;
      padding: 30px;
      background: #FFFFFF;
      margin: 0 auto 30px;
    }
  }
}
.imgcode {
  width: 91px;
  vertical-align: middle;
  margin-left: 20px;
}
</style>